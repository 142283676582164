* {
    margin: 0;
    padding: 0;
    font-size: 100%;
    border: none;
    outline: none;
    font-weight: 300;
    box-sizing: border-box;
    font-family: 'Lato', sans-serif;
}

body {
    background-color: rgb(240,240,240);
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

#tradutor {
    color: rgba(255,255,255,0.5);
    font-size: 90%;
}

#tradutor:hover {
    color: white;
}

#trad {
    color: rgba(255,255,255,0.5);
    font-size: 90%;
}

#trad:hover {
    color: white;
}

#icone {
    margin-right: 5px;
    margin-bottom: 6px;
}

.servicos {
    width: 100%;
    float: left;
    padding: 3% 4%;
}

.servico {
    width: 100%;
    background-color: white;
    text-align: left;
    border-radius: 7px;
    margin-top: 5px
}

.servico img {
    border-radius: 7px 7px 0 0;
}

.inner {
    padding: 7%;
}

.inner h3 {
    font-size: 1.5em;
    color: #130400;
    font-weight: 700;
    text-align: left;
}

.inner h4 {
    font-size: 1.2em;
    color: #130400;
    margin-top: 2%;
}

.inner p {
    margin-top: 6%;
    color: #130400;
    line-height: 1.5em;
}

.citacao1, .citacao2 {
    text-align: right;
}

.sobre {
    width: 100%;
    padding: 2%;
    display: inline-flex;
    justify-content: flex-end;
}

.sobre p {
    width: 70%;
    padding-left: 20%;
    margin: 2%;
    font-size: 70%;
}

.sobre img {
    width: 80px;
    height: 104px;
    border-radius: 7%;
}

.col {
    display: inline-flex;
    justify-content: center;
}

.contato {
    width: 100%;
    text-align: left;
    margin: 3% 0px 3% 0px;
    padding: 3% 4%;
    background-color: #3D423C;
    color: white;
}

.contato h2, h3, h5 {
    font-size: 1.5em;
    color: white;
    text-align: center;
}

.contato h3 {
    font-size: 1.2em;
}

.contato h5 {
    font-size: 0.9em;
}

.contato form {
    margin-top: 2%;
}

.contato input {
    width: 100%;
    height: 30px;
    background-color: white;
    border: 1px solid rgba(255,255,255,0.3);
    padding: 2%;
    border-radius: 5px;
    font-family: "FontAwesome";
}

.contato textarea {
    text-indent: 20px;
    border-radius: 5px;
    font-family: "FontAwesome";
}

.contato label {
    margin-left: 10px;
}

#mensagem {
    width: 100%;
    min-height: 200px;
}

#btn2 {
    width: 100%;
    background-color: #C8C9B5;
    color: white;
    padding: 5%;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 3%;
    font-size: 1.4em;
}

#btn2:hover {
    color: #3D423C;
}

.rodape {
    width: 100%;
    float: left;
    text-align: center;
    background: #C8C9B5;
    color: white;
}

.social-icons a {
    font-size: 1.5em;
    color: white;
    margin: 1%;
} 

.social-icons a:last-child {
    margin-right: 0;
}

.social-icons a:hover {
    color: #3D423C;
}

.social-icons p {
    font-size: 80%;
}

.icone {
    width: 12px;
    height: 12px;
    margin: 0 3px 5px 0;
}

.copyright {
    margin-top: 2%;
    color: white;
    font-size: 60%;
}

.glossario {
    font-size: 70%;
    width: 100%;
    text-align: left;
    color: black;
}

@media screen and (min-width: 992px) {
    .navbar {
        max-height: 115px;
    }

    .navbar-collapse {
        display: flex;
        flex-direction: column-reverse;
    }

    .navbar-nav{
        width: 100%;
        justify-content: flex-end;
        margin-top: 45px;
    }

    #tradutor {
        width: 100%;
        text-align: right;
    }

    #trad {
        display: none;
    }

    .contato {
        max-width: 1024px;
    }
}

@media screen and (min-width: 321px) and (max-width: 991px) {
    .navbar-brand {
        width: 70%;
    }

    .navbar-collapse {
        text-align: right;
    }

    .mobile {
        display: flex;
        flex-direction: column;
    }

    #tradutor {
        display: none;
    }

    #trad {
        width: 100%;
        font-size: 80%;
        text-align: right;
        margin-top: 0px;
        margin-bottom: 5%
    }

    #btn {
        margin-top: 5%;
        float: right;
    }

    #logo {
        float: left;
    }
}

@media screen and (max-width: 320px) {
    .navbar-brand {
        width: 70%;
    }

    .mobile {
        display: flex;
        flex-direction: column;
    }

    #trad {
        display: none;
    }

    .navbar-collapse {
        text-align: center;
    }

    #logo {
        width: 80%;
        float: left;
    }
}